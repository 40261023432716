import { Helmet } from "react-helmet-async";

const OpenGraph = ({ content }) => {
    const { id1, titre,photo, url,desc } = content;
// Valeurs par défaut pour les métadonnées OG
  const ogTitle = titre || "Contenu";
  const share_Url = url || `https://moncoach.tn/Evenement/${id1}`;
  const share_image = photo || "https://moncoach.tn/logo.jpg";
  const share_description = desc || "moncoach";

  return (
        <>
         <Helmet>
        <title>{ogTitle}</title>
        <meta property="og:title" content={ogTitle} />
        <meta property="og:image" content={share_image} />
        <meta property="og:url" content={share_Url} />
        <meta property="og:description" content={share_description} />

      </Helmet>
        </>
    );
}
export default OpenGraph;
