import React, { useEffect, useState } from "react";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import image from "../../images/big_image_2.jpg";
import "./css/evenement.css";
import { useDispatch, useSelector } from "react-redux";
import { GetEvenement } from "../../Redux/Slice/EvenementSlice";
import { Evnt_OG } from "../../Redux/Slice/OGSlice.js";

import Card from "react-bootstrap/Card";
import { BASE_URL_back, getImageUrl } from "../../index.js";
import Newsletter from "../coach/Newsletter";
import Footer from "../coach/Footer";
import { Link } from "react-router-dom";
import { CiCalendarDate } from "react-icons/ci";
import { MdGpsFixed } from "react-icons/md";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import logo from "../../images/logo.jpg";

const Evenement = () => {
  const dispatch = useDispatch();
  const { Evenement } = useSelector((state) => state.evenement);
  const [selectedEvent, setSelectedEvent] = useState({ _id: "", title: "" });
  const [ShowModal, SetShowModal] = useState(false);
  const handleTitleClick = (event) => {
    setSelectedEvent(event);
    console.log(event._id);
    SetShowModal(true);
  };
  const closeModal = () => {
    SetShowModal(false);
    setSelectedEvent({ _id: "", title: "" });
  };
  useEffect(() => {
    dispatch(GetEvenement());
  }, [dispatch]);
  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector(".section-hero");
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top + 25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const shareUrl = selectedEvent
    ? `${BASE_URL_back}/Evenement/${selectedEvent._id}`
    : "";
  console.log(selectedEvent);
  return (
    <>
      <div
        className="ImagePlatformeEvn section-hero"
        style={{ backgroundImage: `url(${image})` }}
      >
        <h3 className="TitreEnvt">Évènements</h3>
      </div>
      <div className="Evnement">
        <div className="ContainerEvnement">
          <Link to="/Evenement/ajouter">
            <button className="partageEvnt">Partagez votre évènement</button>
          </Link>
          <div className="evnt-list">
            {Evenement &&
              Evenement.length > 0 &&
              Evenement.map((Evt) => (
                <div key={Evt._id} className="evnt-card">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={getImageUrl(Evt.photo)}
                      className="Imageevnt"
                    />
                    <Card.Body>
                      <Card.Title
                        className="evnt-card-title"
                        onClick={() => handleTitleClick(Evt)}
                      >
                        {Evt.titre}
                      </Card.Title>
                    </Card.Body>
                    <Card.Body>
                      <div className="evnt-card-author">
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="LieuEvnt"
                        >
                          <CiCalendarDate className="evnt-card-author-icon" />
                          <h3 className="Dateevnt">{Evt.dates}</h3>
                        </div>
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="LieuEvnt"
                        >
                          <MdGpsFixed className="evnt-card-author-icon" />
                          <h3 className="Dateevnt">{Evt.lieu}</h3>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Dialog open={ShowModal} onClose={closeModal} fullWidth maxWidth="md">
        <div style={{ padding: "40px" }}>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              color: "#000",
              zIndex: "1000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <img src={logo} alt="logo" width="220px" height="70" />
          <hr />
          <div>
            <img
              src={selectedEvent ? getImageUrl(selectedEvent.photo) : ""}
              alt="Event"
              className="Evnt-image"
            />
          </div>
          <div className="modal-Evnt">
            <h2 className="Evnt-titre">{selectedEvent?.titre}</h2>
            <div
              className="Evnt-descri"
              dangerouslySetInnerHTML={{ __html: selectedEvent?.texte }}
            />
            <div className="Evnt-info">
              <div className="info-item">
                <CiCalendarDate className="info-icon" />
                <h3 className="info-date">{selectedEvent?.dates}</h3>
              </div>
              <div className="info-item">
                <MdGpsFixed className="info-icon" />
                <h3 className="info-lieu">{selectedEvent?.lieu}</h3>
              </div>
            </div>
            <div className="partagerEVNT">
              <div>
                <FacebookShareButton
                  url={shareUrl}
                  quote={selectedEvent?.titre}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0965FE",
                      paddingRight: "5px",
                    }}
                  >
                    <FacebookIcon style={{ color: "#fff" }} size={20} />
                    <h3 className="info-item">Partage</h3>
                  </div>
                </FacebookShareButton>
              </div>
              <div>
                <LinkedinShareButton url={shareUrl}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0077B5",
                      paddingRight: "5px",
                    }}
                  >
                    <LinkedInIcon style={{ color: "#fff" }} size={20} round />

                    <h3 className="info-item">Partage</h3>
                  </div>
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Evenement;
