import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const Evnt_OG = createAsyncThunk('Evenement/getById', async (id, { rejectWithValue }) => {
  try {
    const res = await axios.get(`/Evenement/${id}`);
    console.log('API Response:', res.data); // Log de la réponse

    return res.data;
  } catch (error) {
    console.error('API Error:', error); // Log de l'erreur

    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});
export const Artc_OG = createAsyncThunk('Artc/OG', async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/articles/${id}`); 
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  });
  export const TEM_OG = createAsyncThunk('TEM/OG', async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/Temoignages/${id}`); 
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  });
export const Default_OG = createAsyncThunk('/OpenGrah', async (id, { rejectWithValue }) => {
  try {
    const res = await axios.get(`/*`);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});
const OGSlice = createSlice({
  name: "OG",

  initialState: {
    html:'',
  select:null,
    isLoading: false,
    error: null,
    token: localStorage.getItem("token1") || null,
    isAuth: localStorage.getItem("isAuth") === 'true',
  },
  reducers: {
    // Your synchronous reducers here
  },
  extraReducers: (builder) => {
    builder
     
      .addCase(   Evnt_OG.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(   Evnt_OG.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.html = action.payload;

      })
      .addCase(   Evnt_OG.rejected, (state, action) => {
        state.isLoading = false;
        state.token = null;
        state.isAuth = false;
        state.error = action.payload;
      })
      
      .addCase(  Default_OG.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(   Default_OG.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.selectedEvenement = action.payload;
      })
      .addCase(   Default_OG.rejected, (state, action) => {
        state.isLoading = false;
        state.token = null;
        state.isAuth = false;
        state.error = action.payload;
      })
      .addCase(   Artc_OG.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(  Artc_OG.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.select= action.payload;
      })
      .addCase(  Artc_OG.rejected, (state, action) => {
        state.isLoading = false;
        state.token = null;
        state.isAuth = false;
        state.error = action.payload;
      })
      .addCase(   TEM_OG.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(  TEM_OG.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.select= action.payload;
      })
      .addCase(  TEM_OG.rejected, (state, action) => {
        state.isLoading = false;
        state.token = null;
        state.isAuth = false;
        state.error = action.payload;
      })
      
  },
});

export default OGSlice.reducer;
