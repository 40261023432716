import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux/Store';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; 

axios.defaults.baseURL = 'https://back.moncoach.tn';

// Chemin de base pour les images
export const BASE_IMAGE_URL = 'https://back.moncoach.tn/';
export const BASE_URL_back = 'https://moncoach.tn';

// Fonction pour obtenir le chemin complet de l'image
export function getImageUrl(imagePath) {
  return BASE_IMAGE_URL + imagePath;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <Router>
          <App />
        </Router>
      </HelmetProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();