import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CiCalendarDate } from "react-icons/ci";
import { MdGpsFixed } from "react-icons/md";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { getImageUrl } from "../../index.js";
import { GetEvenement } from "../../Redux/Slice/EvenementSlice";
import { Evnt_OG } from "../../Redux/Slice/OGSlice.js";

import logo from "../../images/logo.jpg";
import "./css/evenement.css";
import OpenGraph from "./OpenGraph.js";

const EvenementPartage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ogMetaData, setOgMetaData] = useState({});
  const { Evenement } = useSelector((state) => state.evenement);
  const { html } = useSelector((state) => state.og);

  useEffect(() => {
    dispatch(GetEvenement(id));
  }, [dispatch, id]);

  const loadedEvent = Evenement.find((evt) => evt._id === id);

  useEffect(() => {
      dispatch(Evnt_OG(id))
    
  }, [ dispatch]);

  useEffect(() => {
    if (html) {
      const parser = new DOMParser();
      const document = parser.parseFromString(html, "text/html");
      
      const titleMeta = document.querySelector('meta[property="og:title"]');
      const urlMeta = document.querySelector('meta[property="og:url"]');
      const imageMeta = document.querySelector('meta[property="og:image"]');
  
      if (titleMeta && urlMeta && imageMeta) {
        setOgMetaData({
          title: titleMeta.getAttribute("content"),
          url: urlMeta.getAttribute("content"),
          image: imageMeta.getAttribute("content"),
        });
      }
    }
  }, [html]);

  // Valeurs par défaut
  const ogTitle = ogMetaData.title || "Evenement";
  const share_Url = ogMetaData.url || `https://moncoach.tn/Evenement/${id}`;
  const share_image = ogMetaData.image || "https://moncoach.tn/logo.jpg";
  const share_description = ogMetaData.texte || "moncoach";

console.log(id)
  return (
    <>
 <OpenGraph content={{ id1: id, titre: ogTitle, photo: share_image, url: share_Url ,desc: share_description }} />

      <div style={{ padding: "40px" }}>
        <img src={logo} alt="logo" width="220px" height="70" />
        <hr />
        {loadedEvent && (
          <div>
            <img
              src={getImageUrl(loadedEvent.photo)}
              alt="Event"
              className="Evnt-image"
            />
            <div className="modal-Evnt">
              <h2 className="Evnt-titre">{loadedEvent.titre}</h2>
              <div
                className="Evnt-descri"
                dangerouslySetInnerHTML={{ __html: loadedEvent.texte }}
              />
              <div className="Evnt-info">
                <div className="info-item">
                  <CiCalendarDate className="info-icon" />
                  <h5 className="info-date">{loadedEvent.dates}</h5>
                </div>
                <div className="info-item">
                  <MdGpsFixed className="info-icon" />
                  <h5 className="info-lieu">{loadedEvent.lieu}</h5>
                </div>
              </div>
              <div className="partagerEVNT">
                <div>
                  <FacebookShareButton url={share_Url} quote={ogTitle}>
                    <div className="share-button">
                      <FacebookIcon style={{ color: "#fff" }} size={20} />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </FacebookShareButton>
                </div>
                <div>
                  <LinkedinShareButton url={share_Url}>
                    <div className="share-button">
                      <LinkedInIcon style={{ color: "#fff" }} size={20} />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EvenementPartage;